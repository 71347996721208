import {
  DialogContent,
  DialogHead,
  Heading,
  DialogBody,
  RadioGroup,
  Radio,
  Label,
  CheckBox,
  Group,
} from 'xuick'
import { BubbleBlock } from './BubbleBlock'
import { ModalDialog } from './ModalDialog'
import { HeadBackButton } from './HeadBackButton'
import { LoadingIndicator } from './LoadingIndicator'
import api from './api.js'
import './SettingsAccessDialog.css'

export class SettingsAccessDialog extends ModalDialog
{
  static class = 'SettingsAccessDialog'

  static defaultProps = {
    direction : 'right',
  }

  state = {
    ...this.state,
    user : undefined,
  }

  async init() {
    super.init()
    api.on('User', this.#onUser)
    this.on('change', this.#onChange)
    this.setState({
      user : await api.getUser(api.user.id, {
        props : [
          'showStatusTo',
          'showProfileTo',
          'allowSearchName',
          'allowSearchProfile',
          'allowSearchTags',
        ],
      }),
    })
  }

  destroy() {
    super.destroy()
    api.off('User', this.#onUser)
  }

  render() {
    const state = this.state
    const user = state.user
    if(!user) {
      return new LoadingIndicator
    }
    return new DialogContent([
      new DialogHead([
        new HeadBackButton({
          disabled : state.busy,
        }),
        new Heading('Доступ к данным'),
      ]),
      new DialogBody([
        new RadioGroup({
          label : 'Статус',
          classList : 'BubbleBlock',
          name : 'showStatusTo',
          value : user.showStatusTo,
          disabled : state.busy,
          children : [
            new Radio({
              label : 'Только я',
              value : 'user',
            }),
            /*new Radio({
              label : 'Только пользователи из избранного',
              value : 'contacts',
            }),*/
            new Radio({
              label : 'Все',
              value : 'everyone',
            }),
          ],
        }),
        new RadioGroup({
          label : 'Сведения',
          classList : 'BubbleBlock',
          name : 'showProfileTo',
          value : user.showProfileTo,
          disabled : state.busy,
          children : [
            new Radio({
              label : 'Только я',
              value : 'user',
            }),
            /*new Radio({
              label : 'Только пользователи из избранного',
              value : 'contacts',
            }),*/
            new Radio({
              label : 'Все',
              value : 'everyone',
            }),
          ],
        }),
        new BubbleBlock([
          new Label('Доступно в поиске'),
          new Group([
            new CheckBox({
              label : 'Имя и фамилия',
              name : 'allowSearchName',
              checked : user.allowSearchName,
              disabled : state.busy,
            }),
            new CheckBox({
              label : 'Сведения',
              name : 'allowSearchProfile',
              checked : user.allowSearchProfile,
              disabled : state.busy,
            }),
            new CheckBox({
              label : 'Теги',
              name : 'allowSearchTags',
              checked : user.allowSearchTags,
              disabled : state.busy,
            }),
          ]),
        ]),
      ]),
    ])
  }

  async #onChange(e) {
    const { name, value } = e.target
    try {
      this.setState({ busy : true })
      await api.updateUser({
        [name] : value,
      })
    }
    finally {
      this.setState({ busy : false })
    }
  }

  #onUser = user => {
    if(user.id !== api.user.id) {
      return
    }
    this.setState(state => ({
      user : { ...state.user, ...user },
    }))
  }
}
