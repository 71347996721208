import lodash from 'lodash'
import { Main } from 'xuick'
import { Trip } from '../common/Trip.js'
import { InfoMainHead } from './InfoMainHead.js'
import { InfoPlacesBlock } from './InfoPlacesBlock.js'
import { InfoPlaceDialog } from './InfoPlaceDialog.js'

const items = lodash.filter(Trip.keywords, 'filter')

// items.length = 3

export class InfoPlaceGroupsMain extends Main
{
  static class = 'InfoPlaceGroupsMain'

  static getUrl(params) {
    const url = new URL('/info/places', location.origin)
    return Object.assign(url, {
      search : new URLSearchParams(params),
    })
  }

  render() {
    const placeId = this.props.placeId
    return [
      new InfoMainHead('Места'),
      items.map(item => {
        return new InfoPlacesBlock({
          keywords : [item.keyword],
          titleText : item.label,
          placeId,
          href : InfoPlaceGroupsMain.getUrl({
            keyword : item.keyword,
          }),
        })
      }),
      new InfoPlaceDialog({
        objectId : placeId,
        open : !!placeId,
        oncancel : () => history.back(),
      }),
    ]
  }
}
