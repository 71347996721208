export default {
  options : {
    enableHighAccuracy : true,
    maximumAge : 60_000,
    timeout : 5_000,
  },
  getCurrentPosition(options = this.options) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
    })
  },
  watchPosition(successCallback, errorCallback, options = this.options) {
    return navigator.geolocation.watchPosition(successCallback, errorCallback, options)
  },
  clearWatch(watchId) {
    navigator.geolocation.clearWatch(watchId)
  },
  async getLocation() {
    try {
      const { coords } = await this.getCurrentPosition()
      return {
        lat : coords.latitude,
        lng : coords.longitude,
      }
    }
    catch(err) {
      console.error(err)
    }
  },
}

/*
const geolocation = {
  options : {
    enableHighAccuracy : true,
    maximumAge : 60_000,
    timeout : 5_000,
  },
  _position : null,
  _resolvers : null,
  _callbacks : new Map,
  _id : 1,
  getCurrentPosition() {
    if(this._position) {
      return Promise.resolve(this._position)
    }
    this._resolvers = Promise.withResolvers()
    return this._resolvers.promise
  },
  watchPosition(successCallback, errorCallback) {
    this._callbacks.set(this._id, {
      successCallback : successCallback,
      errorCallback : errorCallback,
    })
    return this._id++
  },
  clearWatch(watchId) {
    this._callbacks.delete(watchId)
  },
  async getLocation() {
    try {
      const { coords } = await this.getCurrentPosition()
      return {
        lat : coords.latitude,
        lng : coords.longitude,
      }
    }
    catch(err) {
      console.error(err)
    }
  },
  init() {
    const onSuccess = position => {
      this._position = position
      if(this._resolvers) {
        this._resolvers.resolve(position)
      }
      this._resolvers = null
      for(const callbacks of this._callbacks.values()) {
        callbacks.onSuccess(position)
      }
    }
    const onError = error => {
      console.error(error)
      if(this._resolvers) {
        this._resolvers.reject(error)
      }
      this._resolvers = null
      for(const callbacks of this._callbacks.values()) {
        callbacks.errorCallback(error)
      }
    }
    navigator.geolocation.watchPosition(onSuccess, onError, this.options)
  }
}

geolocation.init()

export default geolocation
*/
