import lodash from 'lodash'
import { TabPanel, MainBlock, HeadBlock } from 'xuick'
import { TripPlaceList } from './TripPlaceList'
import { TripLocationItem } from './TripLocationItem.js'
import { TripPlaceItem } from './TripPlaceItem'
import { TripDurationItem } from './TripDurationItem'
// import { TripServicesItem } from './TripServicesItem.js'
import { TripStayItem } from './TripStayItem.js'
import { TripPlaceAddButton } from './TripPlaceAddButton.js'
import { TripRouteActionsMenu } from './TripRouteActionsMenu.js'
import { TripMenuButton } from './TripMenuButton.js'
import { TripRouteActionsDialog } from './TripRouteActionsDialog.js'
import api from './api'
import './TripRoutePanel.css'

const DIRECTIONS_WAYPOINTS_MAX = 25

export class TripRoutePanel extends TabPanel
{
  static class = 'TripRoutePanel'

  state = {
    ...this.state,
    legs : undefined,
    grabbedItem : null,
    busy : false,
  }

  route

  #timerId = null
  #ids = null
  #items = null
  #rects = null
  #rect = null

  init() {
    super.init()
    this.on('contextmenu', this.#onContextMenu)
    this.on('touchstart', this.#onTouchStart)
    if(this.props.expanded) {
      void this.#getDirections()
    }
  }

  assign() {
    super.assign()
    this.busy = this.state.busy
  }

  render() {
    const { props, state } = this
    const { trip, route } = props
    let leg, duration
    return [
      new HeadBlock([
        new TripRouteActionsMenu({ trip, route }),
        new TripMenuButton({
          dialog : dialogProps => new TripRouteActionsDialog({
            ...dialogProps,
            trip,
            route,
            onload : e => {
              e.target.close()
              location.hash = ''
            },
          }),
        }),
      ]),
      new MainBlock([
        new TripPlaceList([
          new TripLocationItem({
            key : 'PLACE_CURRENT',
          }),
          route.places.map((place, i) => {
            leg = state.legs?.[i - 1]
            duration = undefined
            if(leg) {
              duration = leg.duration
            }
            else if(i && state.legs === null) {
              duration = null
            }
            else if(!i) {
              duration = null
            }
            return [
              new TripDurationItem({
                key : 'DURATION_' + place.id,
                duration,
                trip,
              }),
              /*new TripServicesItem({
                key : 'SERVICES_' + place.id,
                flight : false,
                taxi : !!api.user.role,
                stay : false,
              }),*/
              new TripPlaceItem({
                key : 'PLACE_' + place.id,
                trip,
                routeId : route.id,
                placeId : place.id,
                grabbed : place.id === state.grabbedItem?.placeId,
              }),
            ]
          }),
          new TripDurationItem({
            key : 'DURATION_LAST',
            duration : null,
            trip,
          }),
          /*new TripServicesItem({
            key : 'SERVICES_LAST',
            flight : false,
            taxi : !!api.user.role,
            stay : false,
          }),*/
          new TripStayItem({
            key : 'PLACE_STAY',
            route,
          }),
          /*new TripDurationItem({
            key : 'DURATION_STAY',
            duration : null,
            trip,
          }),
          new TripServicesItem({
            key : 'SERVICES_STAY',
            flight : false,
            taxi : true,
            stay : true,
            disabled : true,
          }),*/
        ]),
        new TripPlaceAddButton({ route }),
      ]),
    ]
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    const { props, state } = this
    if(props.expanded && state.legs === undefined) {
      if(!state.busy) {
        void this.#getDirections()
      }
      return
    }
    if(props.travelMode !== prevProps.travelMode) {
      this.setState({ legs : undefined })
      return
    }
    const idsA = lodash.map(this.route.places, 'id')
    const idsB = lodash.map(prevProps.route.places, 'id')
    if(idsA.join() === idsB.join()) {
      return
    }
    this.#items?.forEach(item => {
      item.node.style.transform = null
    })
    if(state.legs !== null) {
      this.setState({ legs : undefined })
    }
  }

  #onContextMenu(e) {
    e.preventDefault()
  }

  #onTouchStart(e) {
    if(e.touches.length > 1) {
      return
    }
    const grabbedItem = e.target.closest(TripPlaceItem)
    if(!grabbedItem?.placeId) {
      return
    }
    const handler = () => {
      this.setState({ grabbedItem })
      this.#onTouchStartTimeout(e)
    }
    this.on('touchmove', this.#onTouchMove)
    this.on('touchend', this.#onTouchEnd, { once : true })
    this.#timerId = setTimeout(handler, 500)
  }

  #onTouchStartTimeout(e) {
    const [touch] = e.touches
    const item = this.state.grabbedItem
    const rect = item.node.getBoundingClientRect()
    const y = touch.clientY - rect.top - rect.height / 2
    item.node.style.transform = `rotate(2deg) translateY(${ y }px)`
    this.#timerId = null
    this.#items = this.findAll(
      TripPlaceItem,
      item => item.placeId,
    )
    this.#rects = this.#items.map(
      item => item.node.getBoundingClientRect(),
    )
    this.#rect = rect
    this.emit('drag-start', { bubbles : true })
  }

  #onTouchMove(e) {
    const grabbedItem = this.state.grabbedItem
    if(!grabbedItem) {
      if(this.#timerId) {
        clearTimeout(this.#timerId)
        this.#timerId = null
        this.off('touchmove', this.#onTouchMove)
      }
      return
    }
    const arr = Array(this.#items.length)
    const ids = lodash.fill(arr, grabbedItem.placeId)
    const top = this.#rect.top
    const offset = this.#rect.height / 2
    const min = this.#rects[0].top + offset
    const max = this.#rects.at(-1).bottom - offset
    const [touch] = e.touches
    let rect, clientY, y, j
    this.#items.forEach((item, i) => {
      if(item === grabbedItem) {
        clientY = touch.clientY
        clientY = Math.min(clientY, max)
        clientY = Math.max(clientY, min)
        y = clientY - top - this.#rect.height / 2
        item.node.style.transform = `translateY(${ y }px) rotate(2deg)`
        return
      }
      rect = this.#rects[i]
      if(rect.top < top && rect.bottom > touch.clientY) {
        j = i + 1
      }
      else if(rect.top > top && rect.top < touch.clientY) {
        j = i - 1
      }
      else {
        ids[i] = item.placeId
        item.node.style.transform = null
        return
      }
      ids[j] = item.placeId
      y = this.#rects[j].top - rect.top
      item.node.style.transform = `translateY(${ y }px)`
    })
    this.#ids = ids
  }

  #onTouchEnd() {
    clearTimeout(this.#timerId)
    this.#timerId = null
    this.off('touchmove', this.#onTouchMove)
    this.emit('drag-end', { bubbles : true })
    if(!this.state.grabbedItem) {
      return
    }
    const idsA = lodash.map(this.route.places, 'id')
    const idsB = this.#ids
    if(idsB && idsA.join() !== idsB.join()) {
      void this.#updatePlaceOrder()
      return
    }
    this.#items.forEach(item => {
      item.node.style.transform = null
    })
    this.setState({ grabbedItem : null })
    this.#ids = null
    this.#items = null
  }

  async #updatePlaceOrder() {
    this.setState({ busy : true })
    try {
      const ids = this.#ids
      await api.updateRoutePlaces(this.route.id, ids)
    }
    catch(err) {
      this.#items.forEach(item => {
        item.node.style.transform = null
      })
      throw err
    }
    finally {
      this.setState({
        grabbedItem : null,
        busy : false,
      })
      this.#ids = null
      this.#items = null
    }
  }

  async #getDirections() {
    const props = this.props
    const places = props.route.places
    if(places.length < 2) {
      return
    }
    if(places.length > DIRECTIONS_WAYPOINTS_MAX) {
      this.setState({ legs : null })
      return
    }
    const points = places.map(place => {
      const [lng, lat] = place.geometry.coordinates
      return [lat, lng].join()
    })
    const request = {
      origin : points[0],
      destination : points.at(-1),
      waypoints : points.slice(1, -1),
      mode : props.travelMode,
    }
    this.setState({ busy : true })
    try {
      const result = await api.gmsCallMethod('directions', request)
      const [route] = result.routes
      this.setState({
        legs : route?.legs || null,
        busy : false,
      })
    }
    catch(err) {
      this.setState({
        legs : null,
        busy : false,
      })
      console.error(err)
    }
  }
}
