import { SuggestBox } from 'xuick'
import { CountryOption } from './CountryOption'
// import geolocation from '../geolocation.js'
import api from './api'
import './LocalityBox.css'

export class LocalityBox extends SuggestBox
{
  static class = 'LocalityBox'

  static defaultProps = {
    Option : CountryOption,
  }

  location

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    if(prevProps.countryId !== this.props.countryId) {
      this.reset()
    }
  }

  async loadItems(query, limit, offset) {
    return api.findLocalities({
      query,
      countryId : this.props.countryId,
      publicOnly : this.props.publicOnly,
      // location : this.location ||= await geolocation.getLocation(),
      limit,
      offset,
    })
  }

  async loadItem(id) {
    return api.getLocality(id)
  }
}
