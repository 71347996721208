import lodash from 'lodash'
import { TabPanel } from 'xuick'
import { InfoPlacesBlock } from './InfoPlacesBlock.js'
import { InfoPlaceGroupsMain } from './InfoPlaceGroupsMain.js'
import { Trip } from '../common/Trip.js'
import './InfoLocalityPlacesPanel.css'

const items = lodash.filter(Trip.keywords, 'filter')

// items.length = 3

export class InfoLocalityPlacesPanel extends TabPanel
{
  static class = 'InfoLocalityPlacesPanel'

  render() {
    const props = this.props
    if(props.hidden) {
      return
    }
    const localityId = props.locality.id
    return items.map(item => {
      const keyword = item.keyword
      return new InfoPlacesBlock({
        keywords : [keyword],
        titleText : item.label,
        localityId,
        href : InfoPlaceGroupsMain.getUrl({ keyword, localityId }),
      })
    })
  }
}
