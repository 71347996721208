import lodash from 'lodash'
import { HtmlDiv, SvgSvg, SvgPath } from 'xuick'
import { Icon } from './Icon'
import './MapPlaceMarker.css'

const FOCUS_Z_INDEX = 1_000_000
export const MARKER_DEFAULT_COLOR = '#BABABA'
export const MARKER_SEARCH_COLOR = '#784FCD'
export const MARKER_FOCUS_COLOR = '#784FCD'

export class MapPlaceMarker extends HtmlDiv
{
  static class = 'MapPlaceMarker'

  state = {
    focus : false,
    color : undefined,
  }

  place

  #marker = null

  init() {
    super.init()
    this.on('pointerdown', this.#onPointerDown)
  }

  #onPointerDown() {
    this.on('pointermove', this.#onPointerMove, { once : true })
    this.on('pointerup', this.#onPointerUp, { once : true })
  }

  #onPointerMove() {
    this.off('pointerup', this.#onPointerUp)
  }

  #onPointerUp() {
    this.emit('marker-click')
  }

  assign() {
    if(this.state.focus) {
      this.classList = 'focus'
    }
  }

  render() {
    const { props, state } = this
    const color = state.color
    const category = lodash.find(props.place.categories, 'icon')
    return [
      new SvgSvg({
        viewBox : '0 0 42 42',
        children : new SvgPath({
          fill : '#FFFFFF',
          stroke : state.focus && !color ?
            MARKER_FOCUS_COLOR :
            color || MARKER_SEARCH_COLOR,
          strokeWidth : 3,
          transform : 'translate(21,21)',
          d : 'M15.5,-3.15 C15.5,-11.75 8.6,-18.65 0,-18.65 C-8.6,-18.65 -15.5,-11.75 -15.5,-3.15 C-15.5,0.65 -14.1,4.15 -11.8,6.95 C-11.8,6.95 -4.4,15.35 -2.1,17.55 C-0.9,18.65 0.9,18.65 2,17.55 C4.7,14.95 11.7,6.95 11.7,6.95 C14.1,4.15 15.5,0.65 15.5,-3.15z',
        }),
      }),
      category?.icon && new Icon(
        (color && color !== MARKER_DEFAULT_COLOR) || state.focus ?
          category.icon :
          category.icon + '_dim',
      ),
    ]
  }

  mount() {
    const { AdvancedMarkerView } = google.maps.marker
    const props = this.props
    const place = props.place
    const [lng, lat] = place.geometry.coordinates
    this.#marker = new AdvancedMarkerView({
      map : props.map,
      title : place.name,
      position : { lat, lng },
      zIndex : this.zIndex,
      content : this.node,
    })
  }

  destroy() {
    this.#marker.map = null
  }

  setColor(color) {
    this.setState({ color })
  }

  get color() {
    return this.state.color
  }

  set color(color) {
    this.setState({ color })
  }

  get focus() {
    return this.state.focus
  }

  set focus(focus) {
    if(this.state.focus === focus) {
      return
    }
    this.#marker.zIndex = focus ?
      FOCUS_Z_INDEX :
      this.zIndex
    this.setState({ focus })
  }

  get zIndex() {
    const coordinates = this.place.geometry.coordinates
    return Math.floor((90 - coordinates[1]) * 10_000)
  }
}
