import { DateTime } from 'luxon'
import { HtmlDiv, ListItem, Time, Div, Button, Block } from 'xuick'
import { Icon } from './Icon'
import { TripRouteActionsDialog } from './TripRouteActionsDialog.js'
import { TripRouteCommentDialog } from "./TripRouteCommentDialog"
import './TripRouteItem.css'

export class TripRouteItem extends ListItem
{
  static class = 'TripRouteItem'

  route

  init() {
    super.init()
    this.on('click', this.#onClick)
  }

  render() {
    const props = this.props
    const route = props.route
    const locality = route.places[0]?.locality || props.trip.destination
    const dt = DateTime.fromISO(route.date)
    const date = dt.toLocaleString({
      month : 'numeric',
      day : 'numeric',
    })
    return [
      new HtmlDiv([
        new Time({
          dateTime : dt.toISODate(),
          children : `${ date } День ${ props.number }`,
        }),
        new Div(locality.name_ru || locality.name_en),
      ]),
      new Button({
        type : 'menu',
        children : new Icon('menu-vertical_outline_black'),
        popup : {
          modal : true,
          direction : 'bottom',
        },
        onclick : e => e.stopPropagation(),
        dialog : dialogProps => new TripRouteActionsDialog({
          ...dialogProps,
          route,
          trip : props.trip,
          number : props.number,
          onload : e => e.target.close(),
        }),
      }),
      new Block([
        /*new Button({
          hidden : true,
          children : new Icon('plane_outline'),
        }),
        new Button({
          hidden : true,
          children : new Icon('taxi_outline'),
        }),
        new Button({
          hidden : true,
          children : new Icon('occupied-bed_outline'),
        }),*/
        new TripRoutePreview(route.places.map((place, i) => ([
          !!i && ' — ',
          place.name_ru || place.name_en,
        ])))
      ]),
      route.comment && new Button({
        children : new Icon('message_filled'),
        popup : {
          modal : true,
          direction : 'bottom',
        },
        onclick : e => e.stopPropagation(),
        dialog : dialogProps => new TripRouteCommentDialog({
          ...dialogProps,
          route,
          number : props.number,
          onload : e => e.target.close(),
        }),
      }),
    ]
  }

  #onClick() {
    location.hash = this.props.route.date
  }
}

class TripRoutePreview extends HtmlDiv
{
  static class = 'TripRoutePreview'
}
