import lodash from 'lodash'
import { Menu } from 'xuick'
import { Trip } from '../common/Trip.js'
import { MapCategoryItem } from './MapCategoryItem'
// import MapPlaceCategories from '../common/MapPlaceCategories.js'
import './MapCategoryMenu.css'

const MapPlaceCategories = Trip.keywords.map((item, i) => ({
  keyword : item.keyword,
  title : item.label,
  id : ++i,
}))

export class MapCategoryMenu extends Menu
{
  static class = 'MapCategoryMenu'

  #ids

  category = null

  init() {
    super.init()
    this.on('click', this.#onClick)
  }

  render() {
    const category = this.props.category
    const items = category?.items || category?.parent?.items || MapPlaceCategories
    return items.map(item => {
      return new MapCategoryItem({
        key : item.id,
        value : item,
        checked : item.id === category?.id,
      })
    })
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    const items = this.findAll(MapCategoryItem)
    const ids = String(lodash.map(items, 'value'))
    if(ids === this.#ids) {
      return
    }
    this.node.scrollLeft = 0
    this.#ids = ids
  }

  #onClick(e) {
    const item = e.target.closest(MapCategoryItem)
    if(!item) {
      return
    }
    const category = item.value
    this.category = category === this.category ?
      category.parent || null :
      category
    this.emit('change')
  }
}
